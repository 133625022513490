import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().Solutions])} />);


// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './explore.module.scss';
// import Footer from "../../components/shared/Footer/Footer";
// import Upscaling from "../../components/sections/upscaling/Upscaling";
// import ArtifactRemoving from "../../components/sections/artifactRemoving/ArtifactRemoving";
// import OptimizationOptiplus from "../../components/sections/optimizationOptiplus/OptimizationOptiplus";
// import MetadataOptiplus from "../../components/sections/metadataOptiplus/MetadataOptiplus";
// import OptiplusExploreHero from "../../components/sections/optiplusExploreHero/OptiplusExploreHero";
// import AnonymisationOptiplus from "../../components/sections/anonymisationOptiplus/AnonymisationOptiplus";
// import AutoCroppingOptiplus from "../../components/sections/autoCroppingOptiplus/AutoCroppingOptiplus";
// import FileFormatsConversion from "../../components/sections/fileFormatsConversionOptiplus/FileFormatsConversionOptiplus";
// import RotationSizeModificationOptiplus from "../../components/sections/rotationSizeModificationOptiplus/RotationSizeModificationOptiplus";
// import { getRoutes } from '../../components/shared/routes';

// const ExplorePage = () => {
//   const { t } = useTranslation();
//   const pageTitle = `${getRoutes().Explore.pageTitle} | ${getRoutes().OptiwaiPlus.pageTitle}`;


//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Customizable editing workflow'}/>

//       <div className={css.wrapper}>

//         <div className={css.OptiplusExploreHero}>
//             <OptiplusExploreHero />
//         </div>

//         <div className={css.OptimizationOptiplus}>
//             <OptimizationOptiplus />
//         </div>

//         <div className={css.ArtifactRemoving}>
//             <ArtifactRemoving />
//         </div>

//         <div className={css.Upscaling}>
//             <Upscaling />
//         </div>

//         <div className={css.AnonymisationOptiplus}>
//             <AnonymisationOptiplus />
//         </div>

//         <div className={css.AutoCroppingOptiplus}>
//             <AutoCroppingOptiplus />
//         </div>

//         <div className={css.RotationSizeModificationOptiplus}>
//             <RotationSizeModificationOptiplus />
//         </div>

//         <div className={css.FileFormatsConversion}>
//             <FileFormatsConversion />
//         </div>

//         <div className={css.MetadataOptiplus}>
//             <MetadataOptiplus />
//         </div>

//         <div className={css.Footer}>
//           <Footer/>
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default ExplorePage;
